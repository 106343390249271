@import "variables";

@font-face {
  font-family: 'icomoon-odz';
  src:    url('#{$icomoon-font-path}/icomoon-odz.eot?qeca0l');
  src:    url('#{$icomoon-font-path}/icomoon-odz.eot?qeca0l#iefix') format('embedded-opentype'),
  url('#{$icomoon-font-path}/icomoon-odz.ttf?qeca0l') format('truetype'),
  url('#{$icomoon-font-path}/icomoon-odz.woff?qeca0l') format('woff'),
  url('#{$icomoon-font-path}/icomoon-odz.svg?qeca0l#icomoon-odz') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon-odz' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-faq {
  &:before {
    content: $icon-faq;
  }
}
.icon-link {
  &:before {
    content: $icon-link;
  }
}
.icon-bell {
  &:before {
    content: $icon-bell;
  }
}
.icon-doc {
  &:before {
    content: $icon-doc;
  }
}
.icon-facebook {
  &:before {
    content: $icon-facebook;
  }
}
.icon-logo {
  &:before {
    content: $icon-logo;
  }
}
.icon-megafon {
  &:before {
    content: $icon-megafon;
  }
}
.icon-quesrion {
  &:before {
    content: $icon-quesrion;
  }
}
.icon-rss {
  &:before {
    content: $icon-rss;
  }
}
.icon-search {
  &:before {
    content: $icon-search;
  }
}
.icon-tap {
  &:before {
    content: $icon-tap;
  }
}
.icon-twitter {
  &:before {
    content: $icon-twitter;
  }
}

