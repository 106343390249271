.small-txt {
  font-size: 12px;
}

.normal-txt {
  font-size: 14px;
}

.default-txt {
  font-size: 16px;
}

.title-txt-1 {
  font-size: 32px;
}

.title-txt-2 {
  font-size: 24px;
}

.title-txt-3 {
  font-size: 18px;
}