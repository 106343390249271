/***********************************************************************************************************************
 * LAYOUT
 **********************************************************************************************************************/
@for $i from 1 through 15 {
  .spacer#{$i * 10} {
    height: $i * 10 + px;
  }
}

html {
  min-height: 100%; /* make sure it is at least as tall as the viewport */
  position: relative;
}

body {
  height: 100%; /* force the BODY element to match the height of the HTML element */
  color: $g3;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  //padding-top: 50px;
}

.no-gutter {
  margin-left: 0;
  margin-right: 0;
}

.no-gutter > [class*='col-'] {
  padding-right: 0;
  padding-left: 0;
}

.col-xxs-6 {

  @include max768 {
    width: 50%;
    float: left;
  }
  @include max480 {
    width: 100%;
  }

}

/***********************************************************************************************************************
 * BACK To Top
 **********************************************************************************************************************/
#back-to-top.show {
  opacity: 1;
}

#back-to-top {
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 150;
  padding: 10px;
  background-color: rgba($light, 0.5);
  text-align: center;
  line-height: 30px;
  cursor: pointer;
  border: 0;
  /* border-radius: 6px 6px 0 0; */
  text-decoration: none;
  color: $g3;
  transition: opacity 0.2s ease-out;
  opacity: 0;
}

#back-to-top:hover {
  background-color: rgba($light, 0.9);
}

#back-to-top span.icon {
  font-size: 18px;
  line-height: 32px;
  text-align: center;
  width: 32px;
  height: 32px;
}

#back-to-top span.text {
  display: block;
  text-transform: uppercase;
}

/***********************************************************************************************************************
 * FORM
 **********************************************************************************************************************/
.form-control {
  @include border-radius(0);
}

/***********************************************************************************************************************
 * BTN
 **********************************************************************************************************************/
.form-btn {
  background-color: $light;
  color: #fff;
  &:hover, &:focus {
    background-color: $dark;
    color: #fff;
  }
}

.big-icon-btn {
  display: block;
  white-space: normal;
  background-color: $light;
  color: #fff;
  text-align: left;
  &:hover, &:focus {
    background-color: $dark;
    color: #fff;
  }
  position: relative;
  overflow: hidden;
  span.text {
    margin-right: 40px;
    display: block;
  }

  span.btn-icon {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 32px;
  }
}

.print-btn {
  background-color: $light;
  color: #fff;
  span {
    margin-right: 10px;
  }
  &:hover, &:focus {
    background-color: $dark;
    color: #fff;
  }
}

/***********************************************************************************************************************
 * BOOTSTRAP reset
 **********************************************************************************************************************/
.btn {
  @include border-radius(0);
}

.navbar {
  margin: 0;
  padding: 0;
  border: none;
  @include border-radius(0);
}

.navbar-toggle .icon-bar {
  background-color: #fff;
}

.navbar-nav .open .dropdown-menu {
  @include max768 {
    background-color: #fff;
  }
}

.breadcrumb {
  a {
    color: $light;
  }
  .active {
    //color: $light;
    span {
      color: darken($gc, 20%);
    }
  }
}
.pagination>li:first-child>a, .pagination>li:first-child>span,
.pagination>li:last-child>a, .pagination>li:last-child>span{
  @include border-radius(0);
}
.pagination>li>a, .pagination>li>span{
  color: $dark;
}
.pagination>.active>a, .pagination>.active>a:focus, .pagination>.active>a:hover, .pagination>.active>span, .pagination>.active>span:focus, .pagination>.active>span:hover{
  background-color: $light;
  border-color:$light;
  color: #fff;
}

/***********************************************************************************************************************
 * PAGE Layout
 **********************************************************************************************************************/

.page-wrap {
  padding: 20px;
  box-shadow: 0 2px 4px #b3b3b3;
  position: relative;

  @include min768 {
    .sidebar-wrap {
      position: absolute;
      right: 20px;
      top: 20px;
      width: 270px;

    }
    .body-wrap {
      margin-right: 300px;

    }
  }
}

.odzcol-wrap {
  position: relative;
  @include min768 {
    .odzcol-sidebar-wrap {
      position: absolute;
      right: 0;
      top: 0;
      width: 290px;

    }
    .odzcol-body-wrap {
      margin-right: 300px;

    }
  }
}

/***********************************************************************************************************************
 * datepicker
 **********************************************************************************************************************/
.input-sm {
  height: 34px;
}

.input-group-addon {
  @include border-radius(0);
}

.input-daterange input:last-child {
  @include border-radius(0);
}

.input-group-addon:first-child {
  border-left-width: 1px !important;
}

.datepicker table tr td, .datepicker table tr th {
  @include border-radius(0);

}

.datepicker table tr td.active.highlighted:active:focus {

}

.datepicker table tr td.active.active {
  background-color: $light;
  border-color: $light;
}

.datepicker table tr td.selected, .datepicker table tr td.selected.highlighted {
  background-color: $light;
  border-color: $light;
}

#back-to-top.show {
  opacity: 1;
}

#back-to-top .fa {
  font-size: 32px;
  width: 32px;
  height: 32px;
}

#back-to-top span {
  display: block;
}

#back-to-top {
  position: fixed;
  bottom: 0px;
  right: 0;
  z-index: 9999;
  padding: 10px;
  background-color: rgba(33, 47, 51, 0.5);
  text-align: center;
  line-height: 30px;
  cursor: pointer;
  border: 0;
  /* border-radius: 6px 6px 0 0; */
  text-decoration: none;
  color: #fff;
  transition: opacity 0.2s ease-out;
  opacity: 0;
}