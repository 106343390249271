$icomoon-font-path: "../fonts" !default;

$icon-faq: "\e90b";
$icon-link: "\e90a";
$icon-bell: "\e900";
$icon-doc: "\e901";
$icon-facebook: "\e902";
$icon-logo: "\e903";
$icon-megafon: "\e904";
$icon-quesrion: "\e905";
$icon-rss: "\e906";
$icon-search: "\e907";
$icon-tap: "\e908";
$icon-twitter: "\e909";


