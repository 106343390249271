@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
     -moz-border-radius: $radius;
      -ms-border-radius: $radius;
          border-radius: $radius;
}
@mixin reset() {
  margin: 0;
  padding: 0;
}

@mixin box-shadow($top, $left, $blur, $color, $inset: false) {
  @if $inset {
    -webkit-box-shadow:inset $left $top $blur $color;
    -moz-box-shadow:inset $left $top $blur $color;
    box-shadow:inset $left $top $blur $color;
  } @else {
    -webkit-box-shadow: $left $top $blur $color;
    -moz-box-shadow: $left $top $blur $color;
    box-shadow: $left $top $blur $color;
  }
}

@mixin rotate( $val: 45deg ) {
  -webkit-transform: rotate($val);
  -moz-transform: rotate($val);
  -ms-transform: rotate($val);
  -o-transform: rotate($val);
  transform: rotate($val);
}

@mixin transition($args...) {
  -webkit-transition: $args;
  -moz-transition: $args;
  -ms-transition: $args;
  -o-transition: $args;
  transition: $args;
}