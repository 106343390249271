.odz-page-footer {
  position: relative;
  z-index: 100;

  background-color: $footer;
  color: #fff;
  .top-line {
    position: relative;
    .footer-contact-bg {
      display: none;
      position: absolute;
      bottom: 0;
      width: 100%;
      z-index: -1;
      background-color: darken($footer, 3%);
      height: 300px;
      @include min992 {
        &.visible {
          display: block;
        }
      }
      @include min1200 {
        &.visible {
          display: none;
        }
      }
    }
  }

  .footer-menu {

    padding: 30px 0;
    @include min992 {
      margin-left: -30px;
      margin-right: -30px;
    }

    .column {
      padding: 0 30px;
      border-right: 1px solid #1A3621;
      height: 100%;
      &.last {
        border-right: none;
      }
    }
    li {
      list-style: none;
    }
    a {
      color: darken($gd, 20%);
      font-size: 13px;
    }
    .footer-menu-list {
      margin: 0;
      padding: 0;
      .sub-menu-wrap{
        margin-bottom: 10px;
      }

    }
    .footer-menu-list > li > a {
      font-weight: 700;

    }
    .sub-menu {
      padding-left: 15px;
    }
    .sub-menu-title {
      font-weight: 700;
      font-size: 13px;
      color: $gd;
    }
    .sub-menu li > a {
      font-weight: 400;
    }
  }

  .footer-contact {
    position: relative;
    border-top: 1px solid darken($footer, 6%);
    padding-top: 30px;
    padding-bottom: 30px;
    @include min992 {
      background-color: darken($footer, 3%);
    }

    .footer-logo {
      padding-top: 30px;
      text-align: center;
      display: none;

      @include min992 {
        //margin-bottom: 220px;
        float: right;
        width: 480px;
        display: block;
        padding-bottom: 20px;
      }
      @include min1200 {
        width: 100%;
        margin-bottom: 0;
        display: block;
      }
      a {
        padding-left: 15px;
        color: $dark;
        display: inline-block;
        text-decoration: none;

        @include transition(all .2s ease-in);
        &:hover {
          color: $gc;
        }
        .footer-icon {
          float: left;
          font-size: 130px;
        }
        .text {
          float: left;
          padding-top: 18px;
          width: 230px;
          font-size: 50px;
          font-weight: 700;
          line-height: 50px;
          text-align: center;
          margin-left: -10px;
        }
      }
    }
    .footer-contact-bottom {
      position: static;
      @include min992 {
        position: absolute;
        bottom: 20px;

        width: 600px;
      }
      @include min1200{
        width: 100%;
      }

    }

    .footer-social {

      text-align: center;
      margin-bottom: 20px;
      @include min768 {
        margin-bottom: 0;
        border-right: 1px solid darken($footer, 6%);
      }
      a {
        @include min768 {
          display: block;
        }

        font-size: 46px;
        color: #fff;
        text-decoration: none;
        text-align: center;
        &:hover, &:focus {
          //background-color: #fff;
          color: $footer-copyright;
        }
      }
    }
    .contact {
      text-align: center;
      color: #fff;
      @include min768 {
        text-align: left;
      }
      .title {
        margin-top: 0;
        font-size: 18px;
        font-weight: 700;
      }
      .body {
        p {
          strong {
            font-weight: 400;
            color: darken($gd, 20%);
            display: block;
          }
        }
        font-size: 16px;
        a {
          color: $light;
        }
      }

    }
  }
}

.copyright {
  background-color: $footer-copyright;
  padding: 8px 0;
  color: darken($gd, 30%);
  p {
    margin: 0;
    font-size: 12px;
  }
  .apaone {
    background-image: url(../img/apaone-logo.png);
    background-repeat: no-repeat;
    padding-left: 20px;
    background-position: left center;
  }
}
