.odz-page-header {
  background-image: url("../img/zastava-1.jpg");
  background-repeat: no-repeat;
  @include min768 {

    background-size: cover;
  }

  box-shadow: 0 1px 4px #A8A3A3;
  .top-line {
    text-align: center;
    @include min768 {
      text-align: left;
    }
    background-color: rgba(#fff, .93);
    //background-image: url("../img/header-top-bg-2.png");
  }
  .logo {
    a {
      display: block;
      padding: 5px 0;
    }
  }
  .title-text {
    .title {
      font-weight: 700;
      color: $dark;
      margin-top: 10px;
      margin-bottom: 10px;

      @include min992 {
        margin-top: 20px;
      }
    }
    .sub-title {
      font-size: 18px;
      line-height: 22px;
      margin: 0;
      @include min992 {
        font-size: 20px;
      }
    }

  }

  .switches {
    position: relative;
    text-align: right;
    display: none;
    @include min768 {
      height: 100px;
      display: block;
    }
    @include min992 {
      height: 108px;
    }
    .character-switch {
      display: table;
      font-weight: 700;
      float: right;

      button {
        text-transform: uppercase;
        display: table-cell;
        vertical-align: bottom;
        padding: 0;
        text-align: center;
        margin: 0 3px;
        border: none;
        background-color: $light;
        color: #fff;
        &:hover, &:focus {
          background-color: $dark;
        }
      }
      #decreases-char {
        width: 20px;
        font-size: 13px;
        line-height: 20px;
      }
      #reset-char {
        width: 26px;
        font-size: 18px;
        line-height: 26px;
      }
      #increase-char {
        width: 34px;
        font-size: 24px;
        line-height: 34px;
      }
      @include min768 {
        position: absolute;
        bottom: 40px;
        right: 0px;
        float: none;
      }
      @include min992 {
        position: absolute;
        bottom: 10px;
        right: 120px;
        float: none;
      }

    }
    .lang-switch {
      display: inline-block;
      width: 110px;
      .bootstrap-select {
        max-width: 100%;
      }
      .btn {
        text-transform: uppercase;

      }
      @include min768 {
        position: absolute;
        bottom: 0px;
        right: 0;
      }
      @include min992 {
        position: absolute;
        bottom: 10px;
        right: 0;
      }

    }
  }
  .menu-line {
    @include min768 {
      box-shadow: 0 1px 5px #000;
    }

  }
  .bottom-line {
    height: 50px;

  }
  .navbar-spacer {
    height: 1px;
    background-color: rgba(#000, .15);
    position: absolute;
    top: 50px;
    width: 100%;

  }
}

.navbar-odz {
  background-color: $dark;
  .nav > li.active > a {
    background-color: $light;
    color: #fff;

  }
  .nav > li > a {
    color: #fff;
    &:focus, &:hover {
      background-color: $light;
    }
  }
  .nav .open > a {
    background-color: $light;
    color: #fff;
  }
  .container > .navbar-collapse {
    @include min768 {
      margin-right: -15px;
      margin-left: -15px;
    }
  }
  .dropdown-menu li > a {
    &:focus, &:hover {
      color: #fff;
      background-color: $light;
    }
  }
  .dropdown-header {
    font-size: 14px;
    background-color: $light;
    color: #fff;
  }
  .sub-sub-menu {
    background-color: lighten($gc, 16%);
    padding: 0;
    li {
      display: block;
      a {
        color: $g3;
        text-decoration: none;

        display: block;
        padding-top: 3px;
        padding-bottom: 3px;
        padding-right: 20px;
        padding-left: 30px;
      }
    }
  }
  .dropdown-menu {
    min-width: 200%;
    .divider {

      margin: 0;
    }
  }

}