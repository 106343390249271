.sidebar {
  .sidebar-box {
    margin-bottom: 20px;
    .header {
      position: relative;
      background-color: $light;
      .title {
        margin: 0;
        padding: 10px 0 10px 20px;
        color: #fff;
        //font-size: 18px;
        //line-height: 22px;

      }
      .sub-title {
        margin: 0;
        color: #fff;
        //font-size: 16px;
        background-color: $dark;
        margin-right: -5px;
        padding: 6px 5px 6px 20px;
        position: relative;
        z-index: 100;
      }
      span.bg-icon {
        color: rgba(#fff, .8);
        position: absolute;
        top: 8px;
        right: 12px;
        font-size: 40px;
        z-index: 50;
      }
    }
    .body {
      background-color: $gd;
      padding: 20px;
      .doc-list {
        padding: 0;
        li {
          display: block;
          padding-left: 15px;
          //font-size: 12px;
        }
        li:before {
          /*Using a Bootstrap glyphicon as the bullet point*/
          content: "\e022";
          font-family: 'Glyphicons Halflings';
          font-size: 9px;
          float: left;
          margin-top: 4px;
          margin-left: -15px;
          color: $g8;
        }
      }
      .title {
        //font-size: 14px;
        color: #fff;
        background-color: $dark;
        padding: 5px 10px;
        margin-right: -25px;
      }
      .form-group {
        margin-bottom: 5px;
        label {
          //font-size: 13px;
          font-weight: 400;
          margin: 0;
        }
      }
      .form-link {
        margin-top: 15px;
        a {
          display: block;
          //font-size: 13px;
          color:$g6;
          &:hover,&:focus{
            color: $light;
          }
        }
      }

    }
    img{
      display: block;
      max-width: 100%;
    }
  }
}