$light:#00a14b;
$dark:#235138;
$footer:#1f4128;
$footer-copyright:#102114;

$yellow:#feca00;


$g3:#333;
$g6:#666;
$g8:#888;
$gc:#ccc;
$gd:#ddd;
$g-light:#F4F4F4;

