#about {
  .contact-box {
    text-align: left;
    .title {
      font-weight: 300;
    }
    @include max768 {

      margin-left: -15px;
      margin-right: -15px;
    }

  }
  .sw-box {
    @include max768 {
      .title {
        padding: 0 30px;
      }
      .body {
        padding: 0 30px;
      }
    }
    @include min768 {
      .title {
        padding-right: 50px;
      }
      .body {
        padding-right: 50px;
      }
    }

  }
  .rs-box {
    @include max768 {

      .title {
        padding: 0 30px;
      }
      .body {
        padding: 0 30px;
      }
    }
    @include min768 {
      .title {
        padding-left: 50px;
      }
      .body {
        padding-left: 50px;
      }
    }
  }
}