
@import "./modules/bootstrap.min";
@import "../../node_modules/slick-carousel/slick/slick.scss";
@import "../../node_modules/slick-carousel/slick/slick-theme.scss";
@import "../../node_modules/bootstrap-select/sass/variables";
@import "../../node_modules/bootstrap-select/sass/bootstrap-select";
@import "./modules/bootstrap-datepicker3.scss";
@import "./modules/magnific-popup.scss";

@import "./modules/media.scss";
@import "./modules/colors-variables.scss";

@import "./modules/royalslider.scss";
@import "./modules/icomoon";
@import "./modules/mixins.scss";
@import "./modules/typography.scss";



@import "./modules/base.scss";
@import "./modules/header.scss";
@import "./modules/sidenar.scss";
@import "./modules/page-body.scss";
@import "./modules/about.scss";
@import "./modules/menu.scss";
@import "./modules/footer.scss";