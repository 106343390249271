$xx-small:300px;
$x-small:480px;
$small: 768px;
$medium: 992px;
$large : 1200px;
$x-large : 1600px;


@mixin min300 {
	@media (min-width: #{$xx-small}){
		@content;
	}
}
@mixin min480 {
	@media (min-width: #{$x-small}){
		@content;
	}
}
@mixin min768 {
	@media (min-width: #{$small}){
		@content;
	}
}
@mixin min992 {
	@media (min-width: #{$medium}){
		@content;
	}
}


@mixin min992 {
	@media (min-width: #{$medium}){
		@content;
	}
}
@mixin min1200 {
	@media (min-width: #{$large}){
		@content;
	}
}
@mixin min1600 {
	@media (min-width: #{$x-large}){
		@content;
	}
}
@mixin max300 {
	@media (max-width: #{$xx-small - 1px}){
		@content;
	}
}
@mixin max480 {
	@media (max-width: #{$x-small - 1px}){
		@content;
	}
}
@mixin max768 {
	@media (max-width: #{$small - 1px}){
		@content;
	}
}
@mixin max992 {
	@media (max-width: #{$medium - 1px}){
		@content;
	}
}
@mixin tablet {
	@media (min-width: #{$small}) and (max-width: #{$medium - 1px}) {
		@content;
	}
}

@mixin desktop {
	@media (min-width: #{$medium}) {
		@content;
	}
}
