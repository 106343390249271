.odz-page-wrap {
  padding-bottom: 50px;
  .page-sec {
    margin-bottom: 50px;
  }
  .odz-header {
    position: relative;
    .print-btn {
      position: absolute;
      right: 0;
      top: 0;
    }
  }

}

.odz-page-title {
  margin: 0;
  display: inline-block;
  //font-size: 20px;
  padding: 10px 20px;
  background-color: $light;
  color: #fff;
}

.odz-page-body {
  border-top: 5px solid $dark;
  border-bottom: 5px solid $dark;
  padding-top: 30px;
  padding-bottom: 30px;
  a {
    color: $light;
  }
  img {
    max-width: 100%;
  }
  .popup-img-wrap {
    text-align: center;
  }
  .popup-img {
    //@include transition(all .2s ease);
    position: relative;
    display: inline-block;

    img {
      top: 0px;
      left: 0px;
      position: relative;
      @include transition(all .1s ease-in);
      display: inline-block;
      box-shadow: 4px 4px 0 $light;
      border: 1px solid darken($light, 5%);
    }
    .img-icon {
      @include transition(all .2s ease-in);
      position: absolute;
      font-size: 60px;
      color: $dark;
      top: 20px;
      left: 20px;
      //display: none;
      opacity: 0;
    }

    margin-top: 20px;
    margin-bottom: 30px;
    &:hover {
      img {
        opacity: .3;
        background-color: $light;
        top: -4px;
        left: -4px;
        box-shadow: 8px 8px 0 $light;
      }
      .img-icon {
        opacity: .8;
      }

    }
  }
}

/***********************************************************************************************************************
 * HOME
 **********************************************************************************************************************/
.home-slider {
  border-top: 5px solid $dark;
  border-bottom: 5px solid $dark;
}

.home-info {
  border-top: 5px solid $dark;
  border-bottom: 5px solid $dark;
  padding-top: 30px;
  @include max992 {
    .info-box-wrap {
      .info-box {
        border-bottom: 1px solid $dark;
      }
    }
  }

  @include max480 {
    .info-box-wrap:last-child {
      //background-color: red;
      .info-box {
        border-bottom: none;
      }
    }
  }

}

.info-box {
  margin-bottom: 20px;
  //border-bottom: 1px solid $dark;
  text-align: center;

  &.info-box-no-5 {
    //border-bottom: none;
  }
  @include min992 {
    text-align: left;
    border-bottom: none;
  }
  a {
    @include transition(all .2s ease-in);
    display: block;
    text-decoration: none;
    color: $dark;
    &:hover, &:focus {
      background-color: rgba($gc, .3);
      //color:#fff;
      .title {
        color: $light;
      }
      .ico {
        .info-icon {
          color: $light;
          border: 2px solid $light;
        }

      }
    }
  }
  .ico {
    text-align: center;
    padding: 10px;
    @include min992 {
      padding: 30px 0 20px 0;
    }
    .info-icon {
      font-size: 50px;
      line-height: 50px;
      width: 84px;
      border: 2px solid $dark;
      @include border-radius(100px);
      display: inline-block;
      padding: 15px;
      //color: $dark;
    }
  }
  .header {
    @include min992 {
      border-bottom: 1px solid $dark;
    }
    .title {
      margin: 0;
      //font-size: 16px;
      text-transform: uppercase;
      font-weight: 700;
      color: $dark;
      text-align: center;
      padding-bottom: 8px;
    }
  }

  .body {
    padding: 20px;
    //font-size: 14px;
  }
}

.organization-schema {
  .title {
    background-color: $light;
    color: #fff;
    margin: 5px;
    padding: 10px 20px;
  }
  .sub-org {
    a {
      padding: 6px 12px;
      display: block;
      background-color: $light;
      color: #fff;
      margin: 5px;
      text-decoration: none;
      &:hover {
        background-color: $dark;
      }
    }
  }
}

.gde-se-nalazimo {
  .odz-page-body {
    h3 {
      margin-bottom: 40px;
    }
    .kontakt-data {

      p {
        border-bottom: 1px solid $gc;
        margin-bottom: 15px;
        padding-bottom: 5px;
      }
      .glyphicon {
        color: $light;
        margin-right: 10px;
      }
    }

  }
}

.odz-page-sub-title {
  border-bottom: 2px solid $dark;
  margin-bottom: 20px;
  padding-bottom: 10px;
}

#map_container {
  border-top: 5px solid $dark;

  #map_canvas {
    height: 400px;
    position: relative;
    top: 0;
    width: 100%;
  }
}

.full-list-page {
  ul {
    //padding: 0;
    padding-left: 25px;
  }
  .full-search-list {
    .title {

    }
  }
  .full-search-item {
    display: block;
    margin-bottom: 30px;
    border-bottom: 1px solid $gc;

    .date {
      color: $g8;
    }
    .text {
      background-color: lighten($gc, 18%);
      padding: 6px 12px;
      margin-bottom: 0;
      a.learn-more {
        display: inline-block;
        padding-left: 20px;
        position: relative;
        padding-right: 5%;
        span {
          position: absolute;
          right: 3%;
          top: 2px;
        }
        &:hover {
          span {
            position: absolute;
            right: 0px;
          }
        }
      }
    }
    &:before {
      /*Using a Bootstrap glyphicon as the bullet point*/
      content: "\2212";
      font-family: 'Glyphicons Halflings';
      font-size: 9px;
      float: left;
      margin-top: 4px;
      margin-left: -17px;
      color: $light;
    }

  }
}

.institucija-singl {

  background-color: lighten($gc, 18%);
  margin-bottom: 30px;
  .title {
    padding: 8px 15px;
    background-color: $light;
    color: #fff;
  }
  .body {
    padding: 8px 15px;
  }
}

.sluzbeni-list {
  .form-btn {
    margin-bottom: 15px;
    color: #fff;
  }
  .godina {
    padding-top: 30px;
  }
  .scrole-menu {
    a {
      padding: 6px 12px;
      display: inline-block;
      background-color: #00a14b;
      color: #fff;
      margin: 5px;
      text-decoration: none;
      position: relative;
      padding-right: 5%;
      span {
        top: 10px;
        right: 5%;
        position: absolute;
      }
      &:hover {
        background-color: $dark;
        span {
          top: 15px;
        }
      }
    }
  }
  .sluzbeni-list-link {
    display: block;
    padding: 6px 12px;
    text-align: center;
    background-color: $light;
    text-decoration: none;
    color: #fff;
    margin-bottom: 15px;
    &:hover {
      background-color: $dark;
    }
  }
}

.album-prev {
  background-color: $light;
  position: relative;
  overflow: hidden;
  margin-bottom: 30px;
  .img {

  }
  .title {
    position: absolute;
    top: 0;
    width: 100%;
    background-color: $light;
    color: #fff;
    padding: 6px 12px;
    box-shadow: 0 1px 2px lighten(#000, 30%);
  }
  a {
    .glyphicon {
      position: absolute;
      z-index: 500;
      color: #fff;
      font-size: 40px;
      opacity: 0;
      top:50%;
      left: 50%;
      -ms-transform: translate(-50%,-50%); /* IE 9 */
      -webkit-transform: translate(-50%,-50%); /* Safari */
      transform: translate(-50%,-50%);
      @include transition(all .2s ease-in);
    }
    .img {
      @include transition(all .2s ease-in);
    }

    display: block;
    &:hover {
      .img {
        opacity: .5;
      }
      .glyphicon{
        opacity: .9;
      }
    }
  }
}
